import IMenuState from "./type";

const MenuState: IMenuState = {
  showMenuEditForm: false,
  activeMenuType: "",
  activeMenu: "",
  menus: [],
};

export default MenuState;
