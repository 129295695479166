import React from 'react'
import LoyalCustomer from './loyalCustomer'
import Rules from './rules'

export default function Loyalty() {
    return (
        <div>
            <Rules />
            <LoyalCustomer />   
        </div>
    )
}
