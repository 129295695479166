export const ItemsApart = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

export const CenterDiv = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const DefaultPoppins = {
  fontFamily: "Poppins",
  fontWeight: 400,
  fontStyle: "normal",
};

export const MainItemWidth = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "200px",
};

export const SingleItem = {
  fontFamily: "Poppins",
  fontSize: "1.75rem",
  fontStyle: "normal",
  fontWeight: 400,
};

export const EllipsisPosition = {
  top: 15,
  left: 270,
  outline: "none",
  border: "none",
  cursor: "pointer",
  height: 20,
  width: 20,
};

export const OptionPosition = {
  top: 20,
  left: 300,
  cursor: "pointer",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "7px",
  background: "#FFFFFF",
  zIndex: 2000,
  padding: "5px",
};

export const StyleLessButton = {
  outline: "none",
  border: "none",
  background: "white",
  cursor: "pointer",
  margin: "5px",
};
