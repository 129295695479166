import React from "react";

const EllipsisWhite: React.FC = () => {
  return (
    <svg
      width="3"
      height="10"
      viewBox="0 0 3 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.25 7.5C1.58152 7.5 1.89946 7.6317 2.13388 7.86612C2.3683 8.10054 2.5 8.41848 2.5 8.75C2.5 9.08152 2.3683 9.39946 2.13388 9.63388C1.89946 9.8683 1.58152 10 1.25 10C0.918479 10 0.600537 9.8683 0.366117 9.63388C0.131696 9.39946 0 9.08152 0 8.75C0 8.41848 0.131696 8.10054 0.366117 7.86612C0.600537 7.6317 0.918479 7.5 1.25 7.5ZM1.25 3.75C1.58152 3.75 1.89946 3.8817 2.13388 4.11612C2.3683 4.35054 2.5 4.66848 2.5 5C2.5 5.33152 2.3683 5.64946 2.13388 5.88388C1.89946 6.1183 1.58152 6.25 1.25 6.25C0.918479 6.25 0.600537 6.1183 0.366117 5.88388C0.131696 5.64946 0 5.33152 0 5C0 4.66848 0.131696 4.35054 0.366117 4.11612C0.600537 3.8817 0.918479 3.75 1.25 3.75ZM1.25 0C1.58152 0 1.89946 0.131696 2.13388 0.366116C2.3683 0.600537 2.5 0.918479 2.5 1.25C2.5 1.58152 2.3683 1.89946 2.13388 2.13388C1.89946 2.3683 1.58152 2.5 1.25 2.5C0.918479 2.5 0.600537 2.3683 0.366117 2.13388C0.131696 1.89946 0 1.58152 0 1.25C0 0.918479 0.131696 0.600537 0.366117 0.366116C0.600537 0.131696 0.918479 0 1.25 0Z"
        fill="white"
      />
    </svg>
  );
};

export default EllipsisWhite;
