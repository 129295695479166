import ICategoryState from "./type";

const CategoryState: ICategoryState = {
  showCategoryForm: false,
  showEditCategoryForm: false,
  activeCategory: "",
  categories: [],
};

export default CategoryState;
