import React from "react";

export default function VegIcon({style}) {
  return (
    <svg
      width="10"
      height="9"
      viewBox="0 0 10 9"
      fill="none"
      style={style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.61395 0H0.565527C0.432116 0 0.304169 0.0526784 0.209833 0.146447C0.115497 0.240215 0.0625 0.367392 0.0625 0.5V8.5C0.0625 8.63261 0.115497 8.75979 0.209833 8.85355C0.304169 8.94732 0.432116 9 0.565527 9H8.61395C8.74736 9 8.87531 8.94732 8.96965 8.85355C9.06398 8.75979 9.11698 8.63261 9.11698 8.5V0.5C9.11698 0.367392 9.06398 0.240215 8.96965 0.146447C8.87531 0.0526784 8.74736 0 8.61395 0ZM8.11093 8H1.06855V1H8.11093V8Z"
        fill="#2CB041"
      />
      <path
        d="M4.57763 7.00008C5.9667 7.00008 7.09277 5.8808 7.09277 4.5001C7.09277 3.1194 5.9667 2.00012 4.57763 2.00012C3.18856 2.00012 2.0625 3.1194 2.0625 4.5001C2.0625 5.8808 3.18856 7.00008 4.57763 7.00008Z"
        fill="#2CB041"
      />
    </svg>
  );
}
