import SystemColors from "../../constants/colors";

export const CenterDiv = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: 720,
  minWidth: 1280,
  height: "100vh",
  width: "100vw",
};

export const StyleLessButton = {
  outline: "none",
  border: "none",
  background: "white",
  cursor: "pointer",
  color: SystemColors.Blue,
};
