import React from "react";

export default function NonVegIcon({style}) {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      style={style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.55145 0.662842H0.503027C0.369616 0.662842 0.241669 0.71552 0.147333 0.809288C0.0529973 0.903057 0 1.03023 0 1.16284V9.16284C0 9.29545 0.0529973 9.42263 0.147333 9.5164C0.241669 9.61016 0.369616 9.66284 0.503027 9.66284H8.55145C8.68486 9.66284 8.81281 9.61016 8.90715 9.5164C9.00148 9.42263 9.05448 9.29545 9.05448 9.16284V1.16284C9.05448 1.03023 9.00148 0.903057 8.90715 0.809288C8.81281 0.71552 8.68486 0.662842 8.55145 0.662842ZM8.04843 8.66284H1.00605V1.66284H8.04843V8.66284Z"
        fill="#ff6b6b"
      />
      <path
        d="M4.51514 7.66292C5.90421 7.66292 7.03028 6.54365 7.03028 5.16294C7.03028 3.78224 5.90421 2.66296 4.51514 2.66296C3.12607 2.66296 2 3.78224 2 5.16294C2 6.54365 3.12607 7.66292 4.51514 7.66292Z"
        fill="#ff6b6b"
      />
    </svg>
  );
}
