import { IFirebaseConfig } from "../types/firebase.config.type";

const firebaseConfig: IFirebaseConfig = {
  apiKey: "AIzaSyCN3NTGJmiBq67fuN4eMj9DNlOWsGGVXzI",
  authDomain: "on-menu-802c7.firebaseapp.com",
  projectId: "on-menu-802c7",
  storageBucket:"on-menu-802c7.appspot.com",
  messagingSenderId: "638374482167",
  appId:"1:638374482167:web:d20465a774821ad33ec3f2",
  measurementId:"G-4N77W30Q13",
};
export default firebaseConfig;
