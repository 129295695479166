const SystemColors = {
  Red: "#FF3B30FF",
  Orange: "#FF9500FF",
  Yellow: "#FFCC00FF",
  Green: "#34C759",
  Teal: "#5AC8FAFF",
  Blue: "#007AFFFF",
  Indigo: "#5856D6FF",
  Purple: "#AF52DEFF",
  Pink: "#FF2D55FF",
  Gray: "#8E8E93FF",
  Gray_2: "#AEAEB2FF",
  Gray_3: "#C7C7CCFF",
  Gray_4: "#D1D1D6FF",
  Gray_5: "#E5E5EAFF",
  Gray_6: "#F2F2F7FF",
  Primary: "#000000FF",
  Secondary: "#3C3C43",
  Tertiary: "#3C3C434D",
  Quarternary: "#3C3C432E",
  White:"#ffff"
};

export default SystemColors;
