import React from "react";

const BellIcon: React.FC = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.75 7C15.75 5.60761 15.1969 4.27226 14.2123 3.28769C13.2277 2.30312 11.8924 1.75 10.5 1.75C9.10761 1.75 7.77226 2.30312 6.78769 3.28769C5.80312 4.27226 5.25 5.60761 5.25 7C5.25 13.125 2.625 14.875 2.625 14.875H18.375C18.375 14.875 15.75 13.125 15.75 7Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0138 18.375C11.86 18.6402 11.6392 18.8603 11.3735 19.0133C11.1079 19.1664 10.8067 19.2469 10.5001 19.2469C10.1935 19.2469 9.89229 19.1664 9.62663 19.0133C9.36097 18.8603 9.14016 18.6402 8.98633 18.375"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BellIcon;
