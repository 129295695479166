import React from "react";

export default function EggIcon({style}) {
  return (
    <svg
      width="10"
      height="9"
      viewBox="0 0 10 9"
      fill="none"
      style={style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.87177 0H0.823339C0.689928 0 0.561981 0.0526784 0.467646 0.146447C0.37331 0.240215 0.320313 0.367392 0.320312 0.5V8.5C0.320313 8.63261 0.37331 8.75979 0.467646 8.85355C0.561981 8.94732 0.689928 9 0.823339 9H8.87177C9.00518 9 9.13312 8.94732 9.22746 8.85355C9.32179 8.75979 9.37479 8.63261 9.37479 8.5V0.5C9.37479 0.367392 9.32179 0.240215 9.22746 0.146447C9.13312 0.0526784 9.00518 0 8.87177 0ZM8.36874 8H1.32637V1H8.36874V8Z"
        fill="#FFCC00"
      />
      <path
        d="M4.83546 7.00011C6.22454 7.00011 7.35061 5.88083 7.35061 4.50012C7.35061 3.11941 6.22454 2.00012 4.83546 2.00012C3.44638 2.00012 2.32031 3.11941 2.32031 4.50012C2.32031 5.88083 3.44638 7.00011 4.83546 7.00011Z"
        fill="#FFCC00"
      />
    </svg>
  );
}
