import React from "react";

const AddIcon: React.FC = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4999 0.884766C5.18425 0.884766 0.874878 5.24243 0.874878 10.6176C0.874878 15.9928 5.18425 20.3505 10.4999 20.3505C15.8155 20.3505 20.1249 15.9928 20.1249 10.6176C20.1249 5.24243 15.8155 0.884766 10.4999 0.884766ZM11.3749 14.1568C11.3749 14.3915 11.2827 14.6165 11.1186 14.7825C10.9545 14.9484 10.7319 15.0416 10.4999 15.0416C10.2678 15.0416 10.0453 14.9484 9.88116 14.7825C9.71707 14.6165 9.62488 14.3915 9.62488 14.1568V11.5024H6.99988C6.76781 11.5024 6.54525 11.4092 6.38116 11.2433C6.21707 11.0773 6.12488 10.8523 6.12488 10.6176C6.12488 10.3829 6.21707 10.1579 6.38116 9.99196C6.54525 9.82603 6.76781 9.73281 6.99988 9.73281H9.62488V7.07839C9.62488 6.84373 9.71707 6.61868 9.88116 6.45274C10.0453 6.28681 10.2678 6.19359 10.4999 6.19359C10.7319 6.19359 10.9545 6.28681 11.1186 6.45274C11.2827 6.61868 11.3749 6.84373 11.3749 7.07839V9.73281H13.9999C14.2319 9.73281 14.4545 9.82603 14.6186 9.99196C14.7827 10.1579 14.8749 10.3829 14.8749 10.6176C14.8749 10.8523 14.7827 11.0773 14.6186 11.2433C14.4545 11.4092 14.2319 11.5024 13.9999 11.5024H11.3749V14.1568Z"
        fill="#0094FF"
      />
    </svg>
  );
};

export default AddIcon;
